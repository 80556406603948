<template>
  <div v-loading = "pageloading">
    <el-card>
      <div class = "flexRow">
        <div style="flex:1">
          <div class = "firstfont">手机号授权场景配置</div>
          <div class = "grayfont">你可以通过该配置，选择客户在以下哪些场景中，需要授权绑定手机号。部分场景，客户只有当手机号绑定成功，才可操作成功</div>
        </div>
        <el-button type="primary" @click="openconfig">设置场景</el-button>
      </div>
      <div v-if="reslist&&reslist.length">
        <div class = "line"></div>
        <div class = "grayfont">
          <span style="color:#606266">已配置场景：</span>
          <span style="color:#606266">{{getPhoneAuthScenevalue()}}</span>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="configshow" v-loading="configloading" title="手机号授权场景配置" custom-class="dialogpad" width="700px" @close="closedia">
      <div class = "grayfont">
        请选择出现手机号授权绑定的场景：
      </div>
      <div style="margin-top:20px">
        <el-checkbox-group v-model="list">
          <div style="margin:20px 0px">
            <el-checkbox :label="0">每日签到</el-checkbox>
            <div class="grayfont" style="margin:0px 0px 0px 25px">选中该场景，客户绑定手机号成功后，才可继续完成签到</div>
          </div>
          <div style="margin:20px 0px">
            <el-checkbox :label="1">参与助力</el-checkbox>
            <div class="grayfont" style="margin:0px 0px 0px 25px">选中该场景，客户绑定手机号成功后，才可继续帮助好友助力</div>
          </div>
          <div style="margin:20px 0px">
            <el-checkbox :label="2">浏览商品详情</el-checkbox>
            <div class="grayfont" style="margin:0px 0px 0px 25px">选中该场景，客户浏览商品详情页时，系统将引导客户绑定手机号，客户可选择暂不绑定</div>
          </div>
        </el-checkbox-group>
      </div>
      <div style="margin-top:20px;text-align:right">
        <el-button @click="closedia">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  phoneAuthSceneConfiglist,
  phoneAuthSceneConfigsave
}from "@/api/sv1.0.0"
export default {
  data () {
    return {
      pageloading:false,
      configshow:false,
      configloading:false,
      list:[],
      reslist:[],
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    openconfig(){
      this.list = this.reslist.map((v)=>{
        return v.PhoneAuthSceneType
      })
      this.configshow = true
    },
    closedia(){
      this.list = []
      this.configshow = false
    },
    save(){
      this.tosave()
    },
    async tosave(){
      this.configloading = true
      try{
        let newlist = this.list.map((v)=>{
          let msg = {
            Id:0,
            PhoneAuthSceneType:v,
            // PhoneAuthSceneTypeValue:v==0?'每日签到':'参与助力'
          }
          return msg
        })
        let data = {
          PhoneAuthSceneConfigDtos:newlist
        }
        let res = await phoneAuthSceneConfigsave(data)
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.configshow = false
          this.getlist()
        }

      }finally{
        this.configloading = false
      }
    },
    getPhoneAuthScenevalue(){
      return this.reslist.map((v)=>{
        return v.PhoneAuthSceneTypeValue
      }).join(';')
    },
    async getlist(){
      this.pageloading = true
      try{
        let res = await phoneAuthSceneConfiglist()
        if(res.IsSuccess){
          // console.log(res)
          this.reslist = res.Result
          this.list = res.Result.map((v)=>{
            return v.PhoneAuthSceneType
          })
        }
      }finally{
        this.pageloading = false
      }
    },
  }
}
</script>

<style lang = "less" scoped>
  .flexRow{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .firstfont{
    font-size: 18px;
    /* font-weight: bold; */
    color: #303133;

  }
  .grayfont{
    font-size: 14px;
    color: #999999;
    line-height: 1.5;
    margin-top: 15px;
  }
  .line{
    height: 1px;
    background: #EBEEF5;
    margin:10px 0px
  }
</style>
<style>
.dialogpad .el-dialog__body{
  padding:0px 20px 20px 20px
}
</style>